import { useEffect, useRef } from 'react';
import InfiniteScroll from './InfiniteScroll';
const useInfiniteScroll = (props) => {
    const containerRef = useRef(null);
    const { rowCount, columnCount, hasMore: { up, down, left, right }, next, windowScroll, } = props;
    const { current: { setRef, onPropsChange, onCleanup }, } = useRef(new InfiniteScroll(props));
    useEffect(() => {
        setRef(windowScroll ? null : containerRef.current);
        return onCleanup;
    }, [containerRef.current]);
    useEffect(() => onPropsChange(props), [rowCount, columnCount, up, down, left, right, next]);
    return containerRef;
};
export default useInfiniteScroll;
